<template>
    <v-main class="pt-0">
        <cover-image src="/images/covers/home-cover-desktop.jpg"
                     phone-src="/images/covers/home-cover-phone.jpg"
                     tablet-src="/images/covers/home-cover-tablet.jpg"
                     color="#298c9e" secondary-color="#d15914"
                     title="Leila Krejci" subtitle="Musikerin • Model • Content Creator"
                     description="Hey Du! Schön, dass Du da bist!<br/>Mein Name ist Leila - ich bin DIY-Artist aus München und liebe alles, was kreativ ist.<br/>Lass mich Dir zeigen, was ich so mache."
                     action-text="Let's go!"
                     :action-path="`#${sections[0].id}`"
                     :next-section-id="sections[0].id"
                     full-height
                     left
                     main>
            <template #actions>
                <div class="d-flex align-center mt-32">
                    <v-btn v-for="(social, socialKey) in socialLinks" :key="socialKey" variant="text" color="white"
                           :icon="social.icon"
                           size="x-large" :href="social.url" rel="noopener" target="_blank" :title="social.title"/>
                </div>
            </template>
        </cover-image>

        <cover-image v-for="(section, sectionIndex) in sections" :key="sectionIndex"
                     :id="section.id"
                     :title="section.title"
                        :subtitle="section.subtitle"
                        :description="section.description"
                     :src="section.src"
                     :phone-src="section.phoneSrc"
                     :tablet-src="section.tabletSrc"
                     :color="section.color"
                     :secondary-color="section.secondaryColor"
                     :next-section-id="sectionIndex < sections.length - 1 ? sections[sectionIndex + 1].id : undefined"
                     full-height>
            <template v-if="section.buttons?.length" #buttons>
                <v-btn v-for="(button, buttonIndex) in section.buttons"
                       :key="buttonIndex"
                       :to="button.path"
                       :color="button.color ?? section.color"
                       :disabled="button.disabled"
                       size="x-large"
                       :append-icon="button.icon"
                       :variant="button.variant">
                    {{ button.text}}
                </v-btn>
            </template>

            <template v-if="section.id === 'link-with-leila'" #actions>

                <wrapper class="mb-32" stretch>
                    <v-btn v-for="(social, socialKey) in socialLinks" :key="socialKey" variant="outlined"
                           :color="section.secondaryColor"
                           :prepend-icon="social.icon"
                           :text="social.title"
                           size="large" :href="social.url" rel="noopener" target="_blank" :title="social.title"/>
                </wrapper>

                <v-alert v-if="hasClickedOnContactButton" color="#6b6df1"
                         class="contact-form__alert"
                         border="left"
                         elevation="2"
                         colored-border>
                    Vielen Dank!<br>Dein E-Mail-Programm zum Senden der Nachricht sollte sich soeben geöffnet haben.
                    Falls dies nicht der Fall sein sollte, schicke Deine Nachricht bitte an <b>{{ email }}</b>.
                    <br/><br/>
                    <i>{{ contactData.message }}</i>
                </v-alert>

                <v-form v-else @submit.prevent="handleContactSubmit">
                    <v-textarea class="contact-form__message" v-model="contactData.message" rows="7" label="Deine Nachricht"
                                variant="solo"
                                required/>

                    <v-btn type="submit" :color="section.secondaryColor" class="w-full" size="large" :prepend-icon="mdiSendOutline">
                        Kontaktieren
                    </v-btn>

                </v-form>

            </template>
        </cover-image>

        <app-footer dark absolute hide-social/>
    </v-main>
</template>

<script setup lang="ts">
import socialLinks from "../config/social";
import {mdiArrowRight, mdiSendOutline} from "@mdi/js";
import {email} from "../config/main";
import {IndexPageSection} from "../types/pages";

const sections: IndexPageSection[] = [
    {
        id: 'learn-about-leila',
        title: 'Learn about Leila',
        subtitle: 'Bio',
        description: 'Ich bin ein „DIY-Artist" aus München.<br/><br/>Habe ich eine Idee für einen Song, dann greife ich zur Gitarre. Inhaltlich geht es bei mir vor allem um Gerechtigkeit und (Selbst-)Liebe. Ich liebe es in Motiven zu schreiben und mache das vor allem auf Englisch.<br/><br/>Aktuell bin ich an der Point Blank Music School eingeschrieben, um das Music Production Complete Diploma abzuschließen. Damit soll das, was ich selbst in meinem Kopf höre, bald auch Eure Ohren erreichen :-)<br/><br/>Und das ist viel, ein bisschen weird, aber wirklich schön.',
        src: '/images/covers/about-cover-desktop.jpg',
        phoneSrc: '/images/covers/about-cover-phone.jpg',
        tabletSrc: '/images/covers/about-cover-tablet.jpg',
        color: '#040605',
        secondaryColor: '#dc1309',
    },
    {
        id: 'look-at-leila',
        title: 'Look at Leila',
        subtitle: 'Model',
        description: 'Es macht so viel Spaß vor der Kamera zu stehen! Deshalb freue ich mich immer wieder Teil von kreativen Projekten zu sein - egal ob für Shootings oder Videodrehs. Check gerne mal aus, was ich schon so gemacht habe. ',
        src: '/images/covers/graffiti-cover-desktop.jpg',
        phoneSrc: '/images/covers/graffiti-cover-phone.jpg',
        tabletSrc: '/images/covers/graffiti-cover-tablet.jpg',
        color: '#FF8A2F',
        secondaryColor: '#ffc947',
        buttons: [
            {
                text: 'Photo Shoots',
                path: '/photo-shoots',
                color: '#FF8A2F',
                icon: mdiArrowRight,
            },
            {
                text: 'Video Shoots',
                path: '/video-shoots',
                color: '#ffc947',
                icon: mdiArrowRight,
            },
        ]
    },
    {
        id: 'listen-to-leila',
        title: 'Listen to Leila',
        subtitle: 'Musikerin',
        description: 'Musik - always & forever. <br/><br/>Meine erste Performance war „Daylight in your eyes“ von den No Angels auf einem Trampolin.<br/>Seitdem habe ich alles mögliche ausprobiert - von Chor über Hochzeiten bis hin zu Bands. Und ich habe gemerkt, dass ich so viele Ideen habe und einfach gerne meine eigene Musik machen will.<br/><br/>Deshalb schreibe ich, ich singe, ich spiele, ich produce und ich hab Bock.<br/><br/>2024 gibts den ersten Release - das wird schön!',
        src: '/images/covers/gitarre-cover-desktop.jpg',
        phoneSrc: '/images/covers/gitarre-cover-phone.jpg',
        tabletSrc: '/images/covers/gitarre-cover-tablet.jpg',
        color: '#692b12',
        secondaryColor: '#b88e76',
        buttons: [
            {
                text: 'In Kürze verfügbar',
                disabled: true
            },
        ]
    },

    {
        id: 'link-with-leila',
        title: 'Link with Leila',
        subtitle: 'Get in touch',
        src: '/images/covers/laugh-cover-desktop.jpg',
        phoneSrc: '/images/covers/laugh-cover-phone.jpg',
        tabletSrc: '/images/covers/laugh-cover-tablet.jpg',
        color: '#aa0035',
        secondaryColor: '#6b6df1',

    },
];

const contactData = ref({});
const hasClickedOnContactButton = ref(false);

function handleContactSubmit() {
    const subject = encodeURIComponent('Kontaktanfrage über leilakrejci.com')
    const body = encodeURIComponent(contactData.value.message);

    window.location.href = `mailto:${email}?subject=${subject}&body=${body}`;
    hasClickedOnContactButton.value = true;
}
</script>

<style lang="scss">
.contact-form__message, .contact-form__alert {
    min-width: 400px;

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
        min-width: 320px;
    }
}
</style>
